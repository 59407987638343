import { useEffect, useState } from 'react'
import { getCookie } from '@which/shared'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedContent } from '../RecommendedContent'

interface RecommendedForYouContentProps {
  recommendations: Recommendations
  whichId: string
}

export const RecommendedForYouContent = ({
  recommendations,
  whichId,
}: RecommendedForYouContentProps) => {
  const shouldUseSegmentModel = useFeatureValue('use-segment-recommentations', false)
  const modelNameToUse = shouldUseSegmentModel ? 'segment/mixed/adviceOnly' : 'mixed/adviceOnly'

  const modelToUse = recommendations.models.find((model) => model.model == modelNameToUse)

  const { content, model } = modelToUse ?? {}

  if (content && model) {
    return <RecommendedContent content={content} whichId={whichId} modelId={model} />
  }

  return null
}

interface AnonymousRecommendedForYou {
  recommendations: Recommendations
  whichId: string
}

export const AnonymousRecommendedForYou = ({
  recommendations,
  whichId,
}: AnonymousRecommendedForYou) => {
  const showComponent = useFeatureIsOn('DSP-009-anonymous-id-recommended-for-you')

  if (showComponent) {
    return <RecommendedForYouContent recommendations={recommendations} whichId={whichId} />
  }

  return null
}

interface RecommendedForYouContainerProps {
  recommendations: Recommendations
  whichId: string
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
}: RecommendedForYouContainerProps) => {
  const [isAnonymous, setIsAnonymous] = useState(false)

  useEffect(() => {
    const userId = getCookie(document.cookie, 'master_id')
    const anonymousId = getCookie(document.cookie, 'ajs_anonymous_id')
    if (userId || anonymousId) {
      setIsAnonymous(!userId && !!anonymousId)
    }
  }, [])

  if (isAnonymous) {
    return <AnonymousRecommendedForYou recommendations={recommendations} whichId={whichId} />
  }
  return <RecommendedForYouContent recommendations={recommendations} whichId={whichId} />
}
